import * as React from 'react'
import './header.scss';
import {
    Link
} from 'react-router-dom';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { AiOutlineMail } from 'react-icons/ai';
import { FiMessageSquare } from 'react-icons/fi';
import { FiLogOut } from 'react-icons/fi';
interface Props {

}

export const Header: React.FC<Props> = () => {
    return (
        <React.Fragment>

            <nav className="navbar navbar-expand-lg navbar-light header new_header">
                {/* <div className="logo" id="sideLogo">
                    <Link to="/"><h3 className="header_text">Chatlogy</h3>
                </div> */}
                <div className="header_item_center header_width">
                    <div className="left_header_flex">
                        <div className="logo">
                            <Link to="/"><img src="https://chatlogy.ai/images/blue_chatlogy.png" /> </Link>
                        </div>
                        <div className="genex_logo">
                            <img src="https://res.cloudinary.com/chatfuel/image/upload/v1622461141/robi_logo.jpg"/>
                            <p>Robi</p>
                        </div>
                    </div>
                    <div className="right_header_flex">
                        <div className="genex_logo">
                            <p>Munira</p>
                            <img src="assets/avatar.png"/>
                        </div>
                    </div>
                </div>

                {/* <div className="row d-flex justify-content-end w-100">
                    <div className="btn-group">
                        <div data-toggle="dropdown" >
                            <IoMdNotificationsOutline className="headerIcons" />
                        </div>
                        <div className="dropdown-menu dropdown-menu-header fade-in fadeOut">
                            <div className="dropdown-content-heading">
                                <span className="text-left">Recent Notifications</span>
                            </div>
                            <ul>
                                <li className="row notification-item">
                                    <div className="col-sm-2">
                                        <img src="assets/avatar.jpg" />
                                    </div>
                                    <div className="col-sm-7">
                                        <p className="notification-header">Mr. Wolf</p>
                                        <p className="notification">5 member joined today</p>
                                    </div>
                                    <div className="col-md-3">
                                        <p>02:34 PM</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="btn-group">
                        <div data-toggle="dropdown" >
                            <AiOutlineMail className="headerIcons" />
                        </div>
                        <div className="dropdown-menu dropdown-menu-header fade-in">
                            <a className="dropdown-item" href="#">Action 2</a>
                            <a className="dropdown-item" href="#">Another action</a>
                            <a className="dropdown-item" href="#">Something else here</a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#">Separated link</a>
                        </div>
                    </div>
                    <div className="btn-group">
                        <div data-toggle="dropdown" >
                            <FiMessageSquare className="headerIcons" />
                        </div>
                        <div className="dropdown-menu dropdown-menu-header fade-in">
                            <a className="dropdown-item" href="#">Action 3</a>
                            <a className="dropdown-item" href="#">Another action</a>
                            <a className="dropdown-item" href="#">Something else here</a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#">Separated link</a>
                        </div>
                    </div>
                    <div className="btn-group">
                        <div data-toggle="dropdown" >
                            <img className="profile" src="assets/avatar.jpg" />
                        </div>
                        <div className="dropdown-menu dropdown-menu-header fade-in">
                            <a className="dropdown-item" href="#">Log out</a>
                            <a className="dropdown-item" href="#">Another action</a>
                            <a className="dropdown-item" href="#">Something else here</a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#">Separated link</a>
                        </div>
                    </div>


                </div> */}
            </nav>
        </React.Fragment>
    );
}
